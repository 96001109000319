import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "../layouts/dashboard";
import AuthLayout from "../layouts/auth";

// config
import { DEFAULT_PATH } from "../config";
import LoadingScreen from "../components/LoadingScreen";
import { element } from "prop-types";
import AuthUserLayout from "../layouts/auth/UserAuth";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/auth",
      element: <AuthLayout />,
      children: [
        // { path: "login-type", element: <SelectUserTypePage /> },
        { path: "login-admin", element: <LoginAdminPage /> },
        // { path: "register", element: <RegisterPage /> },
        { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "new-password", element: <NewPasswordPage /> },
        // { path: "create-password", element: <CreatePasswordPage /> },
        {path: "verify", element: <VerifyPage /> },
      ]
    },
    {
      path: "/auth",
      element: <AuthUserLayout />,
      children: [
        { path: "loginuser", element: <LoginUserPage /> },
      ]
    },
    {
      path: "/",
      element: <DashboardLayout allowedRoles={['user', 'admin', 'superadmin']}/>,
      children: [
        { element: <Navigate to={DEFAULT_PATH} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
      ],
    },
    {
      path: "/",
      element: <DashboardLayout allowedRoles={['admin', 'superadmin']}/>,
      children: [
        { element: <Navigate to={DEFAULT_PATH} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
        { path: "settings", element: <Settings /> },
        { path: "kbupload", element: <KBupload /> },
        { path: "promptmanage", element: <PromptManage /> },
        { path: "playground", element: <Playground /> },
        { path: "summary", element: <Summary /> },
        { path: "userlist", element: <Userlist /> },
        { path: "config", element: <Config /> },
        
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/app" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp")),
);
const Settings = Loadable(
  lazy(() => import("../pages/dashboard/Settings")),
);
const KBupload = Loadable(
  lazy(() => import("../pages/dashboard/KBupload")),
);
const PromptManage = Loadable(
  lazy(() => import("../pages/dashboard/PromptManage")),
);
const Playground = Loadable(
  lazy(() => import("../pages/dashboard/Playground")),
);
const Summary = Loadable(
  lazy(() => import("../pages/dashboard/Summary")),
);
const Userlist = Loadable(
  lazy(() => import("../pages/dashboard/Userlist")),
);
const Config = Loadable(
  lazy(() => import("../pages/dashboard/Config")),
);
const Page404 = Loadable(lazy(() => import("../pages/Page404")));

// const SelectUserTypePage = Loadable(lazy(() => import("../pages/auth/SelectUserType")));
const LoginAdminPage = Loadable(lazy(() => import("../pages/auth/LoginAdmin")));
// const RegisterPage = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPasswordPage = Loadable(lazy(() => import("../pages/auth/ResetPassword")));
const NewPasswordPage = Loadable(lazy(() => import("../pages/auth/NewPassword")));
// const CreatePasswordPage = Loadable(lazy(() => import("../pages/auth/CreatePassword")));
const VerifyPage = Loadable(lazy(() => import("../pages/auth/Verify")));
const LoginUserPage = Loadable(lazy(() => import("../pages/auth/LoginUser")));