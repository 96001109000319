import { createSlice } from "@reduxjs/toolkit";
// import { faker } from "@faker-js/faker";
// import { AWS_S3_REGION, S3_BUCKET_NAME } from "../../config";

const user_id = window.localStorage.getItem("user_id");

const initialState = {
  direct_chat: {
    conversations: [],
    current_conversation: null,
    current_messages: [],
    room_id: [],
  },
  group_chat: {},
  playground: {
    current_messages: [],
    room_id: null,
  },
};

const slice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    selectPlaygroundRoom(state, action) {
      state.playground.room_id = action.payload.room_id;
    },
    fetchDirectConversations(state, action) {
      const list = action.payload.conversations.map((el) => {
        // const user = el.conversations.find(
        //   (elm) => elm._id.toString() !== user_id
        // );
        return {
          // id: el._id,
          // user_id: user?._id,
          // name: `${user?.firstName} ${user?.lastName}`,
          // online: user?.status === "Online",
        //   img: `https://${S3_BUCKET_NAME}.s3.${AWS_S3_REGION}.amazonaws.com/${user?.avatar}`,
          msg: el.latest_message, 
          roomId: el.room_id, 
          created_at: el.created_at,
          // unread: 0,
          // pinned: false,
          // about: user?.about,
        };
      });

      state.direct_chat.conversations = list;
    },
    updateDirectConversation(state, action) {
      const this_conversation = action.payload.conversation;
      state.direct_chat.conversations = state.direct_chat.conversations.map(
        (el) => {
          if (el?.id !== this_conversation._id) {
            return el;
          } else {
            const user = this_conversation.participants.find(
              (elm) => elm._id.toString() !== user_id
            );
            return {
              id: this_conversation._id._id,
              user_id: user?._id,
              name: `${user?.firstName} ${user?.lastName}`,
              online: user?.status === "Online",
            //   img: faker.image.avatar(),
            //   msg: faker.music.songName(),
              time: "9:36",
              unread: 0,
              pinned: false,
            };
          }
        }
      );
    },
    addDirectConversation(state, action) {
      const this_conversation = action.payload.conversation;
      const user = this_conversation.participants.find(
        (elm) => elm._id.toString() !== user_id
      );
      state.direct_chat.conversations = state.direct_chat.conversations.filter(
        (el) => el?.id !== this_conversation._id
      );
      state.direct_chat.conversations.push({
        id: this_conversation._id._id,
        user_id: user?._id,
        name: `${user?.firstName} ${user?.lastName}`,
        online: user?.status === "Online",
        // img: faker.image.avatar(),
        // msg: faker.music.songName(),
        time: "9:36",
        unread: 0,
        pinned: false,
      });
    },
    setCurrentConversation(state, action) {
      state.direct_chat.current_conversation = action.payload;
    },
    fetchCurrentMessages(state, action) {
      const messages = action.payload.messages;
      const formatted_messages = messages.map((el) => ({
        id: el.id,
        text: el.text? el.text : null,
        type: el.type? el.type : "msg",
        subtype: el.message_type,
        message: el.message,
        time: el.created_at,
        status: el.status,
        incoming: !el.is_user_msg,
        outgoing: el.is_user_msg,
        is_like: el.is_like,
        is_dislike: el.is_dislike
      }));
      state.direct_chat.current_messages = formatted_messages;
    },
    addDirectMessage(state, action) {
      state.direct_chat.current_messages.push(action.payload.message);
    },
    setStreamingMessage(state, action){
      const currentStreamingMessage = state.direct_chat.current_messages.find(
        (elm) => elm.messageId === action.payload.messageId
        )
      if (currentStreamingMessage){ //If messageId exist => update message 
        currentStreamingMessage.message = action.payload.message
        
      }
      else{ //If messageId not exist =>  Add new message 
        state.direct_chat.current_messages.push(action.payload);
      }
    },
    fetchPlaygroundMessages(state, action) {
      const messages = action.payload.messages;
      const formatted_messages = messages.map((el) => ({
        id: el.id,
        text: el.text? el.text : null,
        type: el.type? el.type : "msg",
        subtype: el.message_type,
        message: el.message,
        time: el.created_at,
        status: el.status,
        incoming: !el.is_user_msg,
        outgoing: el.is_user_msg,
        is_like: el.is_like,
        is_dislike: el.is_dislike
      }));
      state.playground.current_messages = formatted_messages;
    },
    setStreamingMessagePlayground(state, action){
      const currentStreamingMessage = state.playground.current_messages.find(
        (elm) => elm.messageId === action.payload.messageId
        )
      if (currentStreamingMessage){ //If messageId exist => update message 
        currentStreamingMessage.message = action.payload.message
        
      }
      else{ //If messageId not exist =>  Add new message 
        state.playground.current_messages.push(action.payload);
      }
    },
    addPlaygroundMessage(state, action) {
      state.playground.current_messages.push(action.payload.message);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const FetchDirectConversations = ({ conversations }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.fetchDirectConversations({ conversations }));
  };
};
export const AddDirectConversation = ({ conversation }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.addDirectConversation({ conversation }));
  };
};
export const UpdateDirectConversation = ({ conversation }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateDirectConversation({ conversation }));
  };
};

export const SetCurrentConversation = (current_conversation) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.setCurrentConversation(current_conversation));
  };
};


export const FetchCurrentMessages = ({messages}) => {
  return async(dispatch, getState) => {
    dispatch(slice.actions.fetchCurrentMessages({messages}));
  }
};

export const FetchPlaygroundMessages = ({messages}) => {
  return async(dispatch, getState) => {
    dispatch(slice.actions.fetchPlaygroundMessages({messages}));
  }
};

export const AddDirectMessage = (message) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.addDirectMessage({message}));
  }
};

export const SetStreamingMessage = (message) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.setStreamingMessage(message));
  }
};

export const SetStreamingMessagePlayground = (message) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.setStreamingMessagePlayground(message));
  }
};


export const AddPlaygroundMessage = (message) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.addPlaygroundMessage({message}));
  }
};

export const SelectPlaygroundRoom = ({ room_id }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.selectPlaygroundRoom({ room_id }));
  };
};