import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
// // import S3 from "../../utils/s3";
// import {v4} from 'uuid';
// import S3 from "../../utils/s3";
// import { S3_BUCKET_NAME } from "../../config";
// ----------------------------------------------------------------------

const initialState = {
  user: {},
  sideBar: {
    open: false,
    type: "CONTACT", // can be CONTACT, STARRED, SHARED
  },
  isLoggedIn: true,
  tab: 0, // [0, 1, 2, 3]
  snackbar: {
    open: null,
    severity: null,
    message: null,
  },
  users: [], // all users of app who are not friends and not requested yet
  all_users: [],
  chat_type: null,
  room_id: null,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchUser(state, action) {
      state.user = action.payload.user;
    },
    updateUser(state, action) {
      state.user = action.payload.user;
    },
    // Toggle Sidebar
    toggleSideBar(state) {
      state.sideBar.open = !state.sideBar.open;
    },
    updateSideBarType(state, action) {
      state.sideBar.type = action.payload.type;
    },
    updateTab(state, action) {
      state.tab = action.payload.tab;
    },

    openSnackBar(state, action) {
      console.log(action.payload);
      state.snackbar.open = true;
      state.snackbar.severity = action.payload.severity;
      state.snackbar.message = action.payload.message;
    },
    closeSnackBar(state) {
      // console.log("This is getting executed");
      state.snackbar.open = false;
      state.snackbar.message = null;
    },
    updateUsers(state, action) {
      state.users = action.payload.users;
    },
    selectConversation(state, action) {
      state.chat_type = "individual";
      state.room_id = action.payload.room_id;
    },
    resetRoomId(state, action) {
      state.room_id = null;
    },
    updateMessage(state, action) {
      const { roomId, message } = action.payload;
      const conversation = state.direct_chat.conversations.find((conv) => conv.room_id === roomId);
      if (conversation) {
        conversation.msg = message; // Update the message field
      }
    },
  },
});

// Reducer
export default slice.reducer;

// // ----------------------------------------------------------------------

export const resetRoomId = () => async (dispatch, getState) => {
  dispatch(slice.actions.resetRoomId());
};

export const closeSnackBar = () => async (dispatch, getState) => {
  dispatch(slice.actions.closeSnackBar());
};

export const showSnackbar =
  ({ severity, message }) =>
  async (dispatch, getState) => {
    dispatch(
      slice.actions.openSnackBar({
        message,
        severity,
      })
    );

    setTimeout(() => {
      dispatch(slice.actions.closeSnackBar());
    }, 4000);
  };

export function ToggleSidebar() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.toggleSideBar());
  };
}
export function UpdateSidebarType(type) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateSideBarType({ type }));
  };
}
export function UpdateTab(tab) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateTab(tab));
  };
}

export function FetchUsers() {
  return async (dispatch, getState) => {
    await axios
      .get(
        "/user/get-users",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        dispatch(slice.actions.updateUsers({ users: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function FetchAllUsers() {
  return async (dispatch, getState) => {
    await axios
      .get(
        "/user/get-all-verified-users",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        dispatch(slice.actions.updateAllUsers({ users: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export const SelectConversation = ({ room_id }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.selectConversation({ room_id }));
  };
};

export const FetchUserProfile = () => {
  return async (dispatch, getState) => {
    axios
      .get("/user/get-me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        dispatch(slice.actions.fetchUser({ user: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateMessage = (roomId, editedMsg) => {
  return async (dispatch, getState) => {
    try {
      await axios.post(
        "/chat/update-message", // Full URL to Django endpoint
        { room_id: roomId, message: editedMsg },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`, // Uncomment if you need to add token
          },
        }
      );
      
      // Dispatch the updated message action if needed (you might want to change this as per your reducer)
      dispatch({
        type: "UPDATE_MESSAGE_SUCCESS",
        payload: { roomId, message: editedMsg },
      });
      
    } catch (error) {
      console.error("Failed to update message:", error);
      // Optionally dispatch an error action
      dispatch({ type: "UPDATE_MESSAGE_ERROR", error });
    }
  };
};


// export const UpdateUserProfile = (formValues) => {
//   return async (dispatch, getState) => {
//     const file = formValues.avatar;

//     const key = v4();

//     try{
//       S3.getSignedUrl(
//         "putObject",
//         { Bucket: S3_BUCKET_NAME, Key: key, ContentType: `image/${file.type}` },
//         async (_err, presignedURL) => {
//           await fetch(presignedURL, {
//             method: "PUT",
  
//             body: file,
  
//             headers: {
//               "Content-Type": file.type,
//             },
//           });
//         }
//       );
//     }
//     catch(error) {
//       console.log(error);
//     }

    

//     axios
//       .patch(
//         "/user/update-me",
//         { ...formValues, avatar: key },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${getState().auth.token}`,
//           },
//         }
//       )
//       .then((response) => {
//         console.log(response);
//         dispatch(slice.actions.updateUser({ user: response.data.data }));
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };