import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
import SideBar from "./SideBar";
import { useSelector } from "react-redux";

const DashboardLayout = ({ allowedRoles }) => {

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { role } = useSelector((state) => state.auth);

  if (!isLoggedIn) {
    return <Navigate to="/auth/loginuser" />;
  }

  return allowedRoles.includes(role) ? 
              <Stack direction='row'>
                <SideBar />
                <Outlet />
              </Stack>
        : <Navigate to="/app" />;
};

export default DashboardLayout;
