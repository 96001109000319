import { Container, Stack } from "@mui/material";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthUserLayout = () => {

    const { isLoggedIn } = useSelector((state) => state.auth);

    if (isLoggedIn) {
        return <Navigate to="/app" />;
    }

    return (
        <>
            <Outlet />
        </>
    );
};

export default AuthUserLayout;
