import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { showSnackbar, resetRoomId } from "./app";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const initialState = {
  isLoggedIn: false,
  token: "",
  isLoading: false,
  isOTPSent: false,
  user: null,
  user_id: null,
  email: "",
  role: "",
  error: false,
  userType: null,
  hasInitialized: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateIsLoading(state, action) {
      state.error = action.payload.error;
      state.isLoading = action.payload.isLoading;
    },
    setHasInitialized(state, action) {
      state.hasInitialized = true;
    },
    updateRole: (state, action) => {
      state.role = action.payload.role;
    },
    logIn(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.token = action.payload.token;
      state.user_id = action.payload.user_id;
      state.email = action.payload.email;
      state.role = action.payload.role;
    },
    updateToken(state, action) {
      state.token = action.payload.token;
    },
    signOut(state, action) {
      state.isLoggedIn = false;
      state.token = "";
      state.user_id = null;
      state.userType = null;
      state.hasInitialized = false;
    },
    updateRegisterEmail(state, action) {
      state.email = action.payload.email;
    },
    updateLoginEmail(state, action) {
      state.email = action.payload.email;
    },
    updateIsOTPSent(state, action) {
      state.isOTPSent = action.payload.isOTPSent;
    },
  },
});

// Reducer
export default slice.reducer;

export function SetHasInitialized() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.setHasInitialized());
}}

export async function refreshAccessToken(dispatch, getState) {
  try {
    // Call the backend to refresh the access token
    const response = await axios.post("/api/auth/refresh-token", {}, { withCredentials: true });

    // Get the new access token from the response
    const newAccessToken = response.data.access;

    // Update the token in the Redux store
    dispatch(slice.actions.updateToken({ token: newAccessToken }));

    return newAccessToken;
  } catch (error) {
    console.error("Failed to refresh access token:", error);

    // If the refresh fails, log the user out and redirect to the login page
    window.localStorage.removeItem("user_id");
    dispatch(resetRoomId());
    dispatch(slice.actions.signOut());
    dispatch(ClearTimer());
    dispatch({ type: "RESET_STATE" }); // Reset the entire Redux state

    dispatch(showSnackbar({ severity: "error", message: "Session expired. Please log in again." }));
    window.location.href = "/auth/loginuser";

    throw error;
  }
}


export function ClearTimer() {
  return async (dispatch, getState) => {
    localStorage.removeItem('remainingTime');
    dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
    dispatch(slice.actions.updateIsOTPSent({ isOTPSent: false }));
  };
}

export function NewPassword(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/api/auth/reset-password/",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        window.localStorage.setItem("user_id", response.data.user_id);
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.access,
            user_id: response.data.id,
            email: response.data.email,
            role: response.data.role,
          })
        );
        dispatch(
          showSnackbar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        console.log(error);
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
  };
}

export function ForgotPassword(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/api/user/forgot-password/",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);

        dispatch(
          showSnackbar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        console.log(error);
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
  };
}

export function LoginAdmin(formValues, onOtpSent) {
  return async (dispatch, getState) => {
    // Make API call here

    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "api/admin/login/send-otp",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(slice.actions.updateLoginEmail({ email: formValues.email }));

        window.localStorage.setItem("user_id", response.data.user_id);
        dispatch(
          showSnackbar({ severity: "success", message: response.data.message })
        );
        dispatch(slice.actions.updateIsOTPSent({ isOTPSent: true }));
        onOtpSent();
      })
      .catch(function (error) {
        console.log(error);
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      })
      .finally(() => {
        dispatch(slice.actions.updateIsLoading({ isLoading: false }));
      });
  };
}

export function LoginUser(formValues, onOtpSent, navigate) {
  return async (dispatch, getState) => {
    // Make API call here

    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/api/user/login/send-otp",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(slice.actions.updateLoginEmail({ email: formValues.email }));

        window.localStorage.setItem("user_id", response.data.user_id);
        dispatch(
          showSnackbar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
        dispatch(
          slice.actions.updateIsOTPSent({ isOTPSent: true })
        );
        
        const role = response.data.role;
        dispatch(slice.actions.updateRole({ role: role }));
        if (role === 'user') {
          if (onOtpSent) onOtpSent();
        }
      })
      
      .catch(function (error) {
        console.log(error);
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
        dispatch(ClearTimer());
      })
      .finally(() => {
        const role = getState().auth.role;
        if (!getState().auth.error && role != 'user' ) {
          dispatch(ClearTimer());
           navigate("/auth/login-admin");
        } 
      });
  };
}

export function LogoutUser() {
  return async (dispatch, getState) => {
    window.localStorage.removeItem("user_id");
    dispatch(resetRoomId());
    dispatch(slice.actions.signOut());
    dispatch(ClearTimer());
    dispatch({ type: "RESET_STATE" }); // Reset the entire Redux state
  };
}

export function RegisterUser(formValues, navigate) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/api/user/register/",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(
          slice.actions.updateRegisterEmail({ email: formValues.email })
        );
        dispatch(
          showSnackbar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        console.log(error);
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate("/auth/verify");
        }
      });
  };
}

export function VerifyEmail(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/api/auth/sendmail/otp/verify/",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        // dispatch(slice.actions.updateRegisterEmail({ email: "" }));
        window.localStorage.setItem("user_id", response.data.user_id);
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.access,
            user_id: response.data.id,
            email: response.data.email,
            role: response.data.role,
          })
        );

        dispatch(
          showSnackbar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        console.log(error);
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      });
  };
}
