import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import appReducer from './slices/app';
import authReducer from './slices/auth';
import conversationReducer from './slices/conversation';
import adminReducer from './slices/admin';

// ----------------------------------------------------------------------

const RESET_STATE = 'RESET_STATE';
const RESET_ADMIN = 'RESET_ADMIN';
const RESET_CONVERSATION = 'RESET_CONVERSATION';

const appInitialState = appReducer(undefined, {});
const authInitialState = authReducer(undefined, {});
const conversationInitialState = conversationReducer(undefined, {});
const adminInitialState = adminReducer(undefined, {});

const appReducerWrapper = (state = appInitialState, action) => {
  if (action.type === RESET_STATE) {
    return appInitialState;
  }
  return appReducer(state, action);
};

const authReducerWrapper = (state = authInitialState, action) => {
  if (action.type === RESET_STATE) {
    localStorage.removeItem('remainingTime'); 
    return authInitialState;
  }
  return authReducer(state, action);
};

const conversationReducerWrapper = (state = conversationInitialState, action) => {
  if (action.type === RESET_STATE) {
    return conversationInitialState;
  }
  if (action.type === RESET_CONVERSATION) {
    return conversationInitialState;
  }
  return conversationReducer(state, action);
};

const adminReducerWrapper = (state = adminInitialState, action) => {
  if (action.type === RESET_ADMIN || action.type === RESET_STATE) {
    return adminInitialState;
  }
  return adminReducer(state, action);
};


const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  //   whitelist: [],
  //   blacklist: [],
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  conversation: conversationReducer,
  admin: adminReducer,
  app: appReducerWrapper,
  auth: authReducerWrapper,
  conversation: conversationReducerWrapper,
  admin: adminReducerWrapper,
});

export { rootPersistConfig, rootReducer };