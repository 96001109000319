import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { refreshAccessToken } from "./auth"; 
import { showSnackbar } from "./app";

const initialState = {
  kb: {
    folders: [],
    files: [],
  },
  prompts: {
    all_prompts: [],
    current_prompt: null,
  },
  users: {
    all_users: [],
  },
  configurations: {
    prompts: [],
    announcements: [],
    greeting_messages: [],
    addition_email_domains: [],
  },
};

const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    fetchKnowledgeBases(state, action) {
      let folderId = 1;
      const fileIdByFolder = {}; // Object to track fileId by folder_name
      let fileId = 1;
      const sortedObjects = action.payload.knowledgebases
          .sort((a, b) => {
            const dateA = new Date(a.Last_Modified_Date);
            const dateB = new Date(b.Last_Modified_Date);
        
            if (isNaN(dateA) && isNaN(dateB)) {
              // Both dates are invalid, sort by folder_name ascending
              return a.folder_name.localeCompare(b.folder_name);
            } else if (isNaN(dateA)) {
              // Only dateA is invalid, place a after b
              return 1;
            } else if (isNaN(dateB)) {
              // Only dateB is invalid, place b after a
              return -1;
            } else {
              // Both dates are valid, sort by Last_Modified_Date descending
              return dateB - dateA;
            }
          })
          .map((obj) => {
              if (obj.type === 'folder') {
              return {
                  ...obj,
                  id: folderId++, // Increment folderId for folders
              };
              } else {
                // For files, assign ID based on folder_name
                if (!fileIdByFolder[obj.folder_name]) {
                    fileIdByFolder[obj.folder_name] = 1; // Initialize if not present
                }
                const fileId = fileIdByFolder[obj.folder_name]++;
                return {
                    ...obj,
                    id: fileId, // Use folder-specific file ID
              };
              }
          });
      const listFolder = [];
      const listFiles = [];
      sortedObjects.forEach((el) => {
        if (el.type === 'folder') {
          listFolder.push({
            id : el.id,
            type: el.type,
            folder_name: el.folder_name,
            Description: el.Description,
            Last_Modified_Date: el.Last_Modified_Date ? el.Last_Modified_Date.split('+')[0] : el.Last_Modified_Date,
            status: el.status,
          });
        } else {
          listFiles.push({
            id : el.id,
            type: el.type,
            folder_name: el.folder_name,
            file_name: el.file_name,
            size: (el.size / 1024).toFixed(2) + ' KB',
            Last_Modified_Date: el.Last_Modified_Date ? el.Last_Modified_Date.split('+')[0] : el.Last_Modified_Date,
            status: el.status,
          });
        }
      });
      state.kb.folders = listFolder;
      state.kb.files = listFiles;
    },
    addKBFolder(state, action) {
      const maxId = Math.max(
        ...state.kb.folders.map(folder => folder.id),
        0 // Ensure at least 0 if no items exist
      );
      state.kb.folders.push({
        id: maxId + 1, // Assign a new ID that is 1 greater than the current max ID
        type: action.payload.type,
        folder_name: action.payload.folder_name,
        Description: action.payload.Description,
        Last_Modified_Date: action.payload.Last_Modified_Date,
        status: 'Creating',
      });
      FetchKnowledgeBases(state.kb.folders);
    },
    updateKBFolderStatus(state, action) {
      const folder_name_to_update = action.payload.folder_name;
      const status_to_update = action.payload.status;
      const state_files = state.kb.files.filter(current_file => current_file.folder_name === folder_name_to_update);
      const state_folders = state.kb.folders.filter(current_folder => current_folder.folder_name === folder_name_to_update);
      
      state_files.forEach(state_file => {
        state_file.status = status_to_update;
      });
      state_folders.forEach(state_folder => {
        state_folder.status = status_to_update;
      });
    },
    deleteKBFolder(state, action) {
      const folderName = action.payload.folder_name;
      const folder = state.kb.folders.find(folder => folder.folder_name === folderName);
      if (folder) {
        folder.status = 'Deleting';
      }
      FetchKnowledgeBases(state.kb.folders);
    },
    
    addKBFile(state, action) {
      let maxId = Math.max(
        ...state.kb.files.map(file => file.id),
        0 // Ensure at least 0 if no items exist
      );

      action.payload.forEach(file => {
        const file_name_to_update = file.file_name;
        const file_type_to_update = file.type;
        const state_file = state.kb.files.find(current_file => current_file.file_name === file_name_to_update && current_file.type === file_type_to_update);
        if (state_file) {
          state_file.status = 'Uploading';
        }
        else {
          state.kb.files.push({
            id: ++maxId, // Increment maxId for each new file
            type: file.type,
            folder_name: file.folder_name,
            file_name: file.file_name,
            size: file.size,
            Last_Modified_Date: file.Last_Modified_Date,
            // Last_Modified_Date: '',
            status: 'Uploading',
          });
        }
      });
    },
    updateKBFileStatus(state, action) {
      const files = action.payload.files;
      const status_to_update = action.payload.status;
      files.forEach(file => {
        const file_name_to_update = file.file_name;
        const state_file = state.kb.files.find(current_file => current_file.file_name === file_name_to_update);
        if(state_file) {
          console.log(state_file.file_name);
          state_file.status = status_to_update;
          console.log(state_file.status);
        }
      });
    },
    removeKBFile(state, action) {
      const fileName = action.payload.file_name;
      state.kb.files = state.kb.files.filter(file => file.file_name !== fileName);
    },
    fetchPromptlist(state, action) {
      let Id = 1;
      const sortedMessages = action.payload.messages
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
            // const dateA = new Date(a.last_modified);
            // const dateB = new Date(b.last_modified);
          })
          .map((obj) => {
              return {
                  ...obj,
                  id: Id++, // Increment folderId for folders
              };
              }
          );
      const listPrompts = [];
      sortedMessages.forEach((el) => {
        listPrompts.push({
            id : el.id,
            config_type : el.config_type,
            name : el.name,
            description: el.description,
            text : el.text,
            is_active : el.is_active,
            last_modified: el.last_modified ? el.last_modified.split('+')[0] : el.last_modified
          });
        }
      );
      state.prompts.all_prompts = listPrompts;
    },
    addPrompt(state, action) {
      let maxId = Math.max(
        ...state.prompts.all_prompts.map(prompt => prompt.id),
        0 // Ensure at least 0 if no items exist
      );
      state.prompts.all_prompts.push({
        id: ++maxId, // Increment maxId for each new file
        config_type: "prompt",
        name: action.payload.name,
        description: action.payload.description,
        text: action.payload.text,
        is_active: false,
        last_modified: new Date().toLocaleString(),
      });
    },
    fetchUserlist(state, action) {
      let Id = 1;
      const sortedEmail = action.payload.messages
          .sort((a, b) => {
            const dateA = new Date(a.email);
            const dateB = new Date(b.email);
          })
          .map((obj) => {
              return {
                  ...obj,
                  id: Id++, // Increment folderId for folders
              };
              }
          );
      const listUsers = [];
      sortedEmail.forEach((el) => {
        listUsers.push({
            id : el.id,
            email : el.email,
            last_login: el.last_login,
            role: el.role,
            registered_at: el.registered_at ? el.registered_at.split('+')[0] : el.registered_at,
          });
        }
      );
      state.users.all_users = listUsers;
    },
    addNewUser(state, action) {
      let maxId = Math.max(
        ...state.users.all_users.map(user => user.id),
        0 // Ensure at least 0 if no items exist
      );
      state.users.all_users.push({
          id: ++maxId, // Increment maxId for each new file
          email : action.payload.email,
          last_login: null,
          role: action.payload.role,
          registered_at: action.payload.registered_at,
        });
    },
    fetchConfigurations(state, action) {
      let PromptId = 1;
      let GreetingMessageId = 1;
      let AdditionEmailDomainId = 1;
      let AnnouncementId = 1;
  
      const objects = action.payload;
      const sortedobjects = objects
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((obj) => {
              if (obj.config_type === 'prompt') {
                  return {
                      id: PromptId++,
                      ...obj,
                  };
              } else if (obj.config_type === 'greeting_message') {
                  return {
                      id: GreetingMessageId++,
                      ...obj,
                  };
              } else if (obj.config_type === 'addition_email_domain') {
                  return {
                      id: AdditionEmailDomainId++,
                      ...obj,
                  };
              } else if (obj.config_type === 'announcement') {
                  return {
                      id: AnnouncementId++,
                      ...obj,
                  };
              }
              return obj; // return obj unchanged if it doesn't match any config_type
          });
  
      const listPrompts = [];
      const listGreetingMessages = [];
      const listAdditionEmailDomain = [];
      const listAnnouncements = [];
  
      sortedobjects.forEach((el) => {
          const commonFields = {
              id: el.id,
              config_type: el.config_type,
              name: el.name,
              description: el.description,
              text: el.text,
              is_selected: el.is_selected,
              is_active: el.is_active,
              last_modified: el.last_modified ? el.last_modified.split('+')[0] : el.last_modified,
          };
          
          if (el.config_type === 'prompt') {
              listPrompts.push(commonFields);
          } else if (el.config_type === 'greeting_message') {
              listGreetingMessages.push(commonFields);
          } else if (el.config_type === 'addition_email_domain') {
              listAdditionEmailDomain.push(commonFields);
          } else if (el.config_type === 'announcement') {
              listAnnouncements.push(commonFields);
          }
      });
  
      state.configurations.announcements = listAnnouncements;
      state.configurations.prompts = listPrompts;
      state.configurations.greeting_messages = listGreetingMessages;
      state.configurations.addition_email_domains = listAdditionEmailDomain;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const FetchKnowledgeBases = ({ knowledgebases }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.fetchKnowledgeBases({ knowledgebases }));
  };
};
export const AddKBFolder = ( folder ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.addKBFolder( folder ));
  };
};
export const UpdateKBFolderStatus = ( folder ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateKBFolderStatus( folder ));
  };
};
export const DeleteKBFolder = ( folder ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.deleteKBFolder( folder ));
  };
};
export const RemoveKBFile = ( folder ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.removeKBFile( folder ));
  };
};
export const AddKBFile = ( files ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.addKBFile( files ));
  };
};
export const UpdateKBFileStatus = ( files ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateKBFileStatus( files ));
  };
};
export const FetchUserlist = ({ messages }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.fetchUserlist({ messages }));
  };
};
export const AddNewUser = ( user ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.addNewUser( user ));
  };
};
export const FetchPromptlist = ({ messages }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.fetchPromptlist({ messages }));
  };
};
export const AddPrompt = ( prompt ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.addPrompt( prompt ));
  };
};
export const FetchConfigurations = ( messages ) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.fetchConfigurations( messages ));
  };
};

// Helper to get the latest token (handles refresh logic)
const getValidToken = async (dispatch, getState) => {
  let token = getState().auth.token;

  // Refresh token if it's not available
  if (!token) {
    token = await refreshAccessToken(dispatch, getState);
  }

  return token;
};


export const apiCreateKbFolder = (folder_name, Description) => {
  return async (dispatch, getState) => {
    try {
      const token = await getValidToken(dispatch, getState);

      const response = await axios.post(
        "/api/admin/kb/create-folder",
        { folder_name, Description },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const apiDeleteKbFolder = (folder_name) => {
  return async (dispatch, getState) => {
    try {
      const token = await getValidToken(dispatch, getState);

      const response = await axios.post(
        "/api/admin/kb/delete-folder",
        { object_type: "folder", object_name: folder_name },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const apiUploadKbFile = (formData) => {
  return async (dispatch, getState) => {
    try {
      const token = await getValidToken(dispatch, getState);

      const response = await axios.post(
        "/api/admin/kb/upload-file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const apiDeleteKbFile = (folder_name, file_name) => {
  return async (dispatch, getState) => {
    try {
      const token = await getValidToken(dispatch, getState);

      const response = await axios.post(
        "/api/admin/kb/delete-file",
        { object_type: "file", folder_name, object_name: file_name },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const apiDownloadKbFile = (file_key) => {
  return async (dispatch, getState) => {
    try {
      const token = await getValidToken(dispatch, getState);

      const response = await axios.post(
        "/api/admin/kb/download-file",
        { file_keys: [file_key] },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const apiRegisterAdmin = (body) => {
  return async (dispatch, getState) => {
    try {
      const token = await getValidToken(dispatch, getState);

      const response = await axios.post(
        "/api/admin/register/",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      dispatch(showSnackbar({ severity: response.data.severity, message: response.data.message }));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
};

export const apiDeleteUser = (body) => {
  return async (dispatch, getState) => {
    try {
      const token = await getValidToken(dispatch, getState);

      const response = await axios.post(
        "/api/auth/delete-user/",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      dispatch(showSnackbar({ severity: response.data.severity, message: response.data.message }));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
};

export const apiEditRole = (body) => {
  return async (dispatch, getState) => {
    try {
      const token = await getValidToken(dispatch, getState);

      const response = await axios.post(
        "/api/admin/edit-role/",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      dispatch(showSnackbar({ severity: response.data.severity, message: response.data.message }));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
};