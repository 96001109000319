import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useTheme} from "@mui/material/styles";

import KTBLogo from "../../assets/Images/Krung_Thai_Bank_logo.svg.ico";
import { Nav_Buttons, Profile_Menu } from "../../data";
import useSettings from "../../hooks/useSettings";
import MaterialUISwitch from "../../components/MaterialUISwitch";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser } from "../../redux/slices/auth";
import { SignOut } from "phosphor-react";


const getPath = (index) => {
    switch (index) {
        case 0:
            return "/app";
        case 1:
            return "/kbupload";
        case 2:
            return "/promptmanage";
        case 3:
            return "/playground";
        case 4:
            return "/summary";
        case 5:
            return "/userlist";
        case 6:
            return "/config";
        default:
            break;
    }
}


const getMenuPath = (index) => {
    switch (index) {
        case 0:
            return "/settings";
        case 1:
            // TODO => Update token & set isAuth = false
            return "/auth/login";
        //   case 2:
        //     // TODO => Update token & set isAuth = false
        //     return "/auth/login";
        default:
            break;
    }
}

const SideBar = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [selected, setSelected] = useState(0);
    const { onToggleMode } = useSettings();

    const navigate = useNavigate();
    const [anchorEL, setAnchorEL] = React.useState(null);
    const open = Boolean(anchorEL);
    const handleClick = (event) => {
        setAnchorEL(event.currentTarget);
        // alert(event.currentTarget);
        // navigate();
    }

    const handleClose = () => {
        setAnchorEL(null);

    };
    const {role} = useSelector((state) => state.auth);

    return (
        <Box p={2} sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            height: "100vh", width: 100
        }}
        >
            <Stack
                direction="column"
                alignItems={"center"}
                sx={{ height: "100%" }}
                justifyContent={"space-between"}
                spacing={3}
            >

                <Stack alignItems={"center"} spacing={4}>

                    {/* <Box sx={{backgroundColor: theme.palette.primary.main, 
                    height:64, width: 64,
                    borderRadius: 1.5,
                    }}> */}
                    <img src={KTBLogo} alt={"Chat App Logo"} />
                    {/* </Box> */}

                    <Stack
                        direction="column"
                        alignItems={"center"}
                        sx={{ width: "max-content" }}
                        spacing={3}
                    >
                        {Nav_Buttons.filter((el) => {
                                // Check if role is "user" and Don't show any icon
                                if (role === "user") {
                                    return el.description === "";
                                }
                                return true; // Show all buttons for other roles
                            }).map((el) => (
                            el.index === selected ?
                                <Box
                                    p={1}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 1.5
                                    }}
                                >
                                    <Tooltip title={el.description} arrow>
                                        <IconButton
                                            sx={{ width: "max-content", color: "#fff" }}
                                            key={el.index}
                                        >
                                            {el.icon}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                :
                                <Tooltip title={el.description} arrow>
                                    <IconButton
                                        onClick={() => {
                                            setSelected(el.index);
                                            navigate(getPath(el.index));
                                        }}
                                        sx={{ width: "max-content", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                        key={el.index}
                                    >
                                        {el.icon}
                                    </IconButton>
                                </Tooltip>
                        ))}


                        {/* <Divider sx={{ width: "48px" }} />

                        {selected === 3 ?
                            <Box
                                p={1}
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: 1.5
                                }}
                            >
                                <IconButton sx={{ width: "max-content", color: "#fff" }}>
                                    <Gear />
                                </IconButton>
                            </Box>
                            : <IconButton
                                onClick={() => {
                                    setSelected(3);
                                }}
                                sx={{ width: "max-content", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                            >
                                <Gear />
                            </IconButton>
                        } */}
                    </Stack>

                </Stack>

                <Stack spacing={4} direction="column" alignItems={"center"} >
                    <MaterialUISwitch onChange={() => {
                        onToggleMode();
                    }} defaultChecked />
                    <IconButton id="basic-IconButton"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick} sx={{ color: "#555" }}><SignOut/></IconButton>
                    <Menu id='basic-menu'
                        anchorEl={anchorEL}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                    >
                        <Stack spacing={1} px={1}>
                            {Profile_Menu.map((el, idx) => (
                                <MenuItem onClick={() => {
                                    handleClick();
                                }}>
                                    <Stack
                                        onClick={() => {
                                            // If idx is 2 then dispatch logout
                                            if (idx === 0) {
                                                dispatch(LogoutUser());
                                            } else {
                                                navigate(getMenuPath(idx));
                                            }
                                        }}
                                        sx=
                                        {{ width: 100 }}
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}>
                                        <span>{el.title}</span>
                                        {el.icon}
                                    </Stack>{""}
                                </MenuItem>
                            ))}
                        </Stack>
                    </Menu>
                </Stack>

            </Stack>
        </Box>
    )
}

export default SideBar
