import { faker } from "@faker-js/faker";
import {
  Book,
  ChatCircleDots,
  Gear,
  GearSix,
  SignOut,
  User,
  Users,
} from "phosphor-react";

import {ChartDonut, ChatCircleSlash, Textbox} from "@phosphor-icons/react"
import KTBLogo from "../assets/Images/Krung_Thai_Bank_logo.svg.ico";

const Profile_Menu = [
  // {
  //   title: "Details",
  //   icon: <User />,
  // },
  // {
  //   title: "Settings",
  //   icon: <Gear />,
  // },
  {
    title: "Logout",
    icon: <SignOut />,
  },
];

const Nav_Buttons = [
  {
    index: 0,
    icon: <ChatCircleDots />,
    description: "Chat"
  },
  {
    index: 1,
    icon: <Book />,
    description: "Knowledge base"
  },
  {
    index: 2,
    icon: <Textbox/>,
    description: "Prompt"
  },
  {
    index: 3,
    icon: <ChatCircleSlash  />,
    description: "Playground"
  },
  {
    index: 4,
    icon: <ChartDonut />,
    description: "Chart"
  },
  {
    index: 5,
    icon: <Users />,
    description: "Userlist"
  },
  {
    index: 6,
    icon: <Gear />,
    description: "Config"
  },
];

const Nav_Setting = [
  {
    index: 5,
    icon: <GearSix />,
  },
];

const ChatList = [
  {
    id: 0,
    img: KTBLogo,
    name: "History 1",
    msg: "Description1...",
    time: "9:36",
    bot_yesterday: true,
  },
  {
    id: 1,
    img: KTBLogo,
    name: "History 2",
    msg: "Description2...",
    time: "12:02",
    unread: 2,
    bot_today: true,
  },
  {
    id: 2,
    img: KTBLogo,
    name: "History 3",
    msg: "Description3...",
    time: "10:35",
    bot_lastweek: true,
  },
  {
    id: 3,
    img: KTBLogo,
    name: "History 4",
    msg: "Description4...",
    time: "10:35",
    bot_lastmonth: true,
  },
  {
    id: 4,
    img: KTBLogo,
    name: "History 5",
    msg: "Description5...",
    time: "10:35",
    bot_lastweek: true,
  },
  {
    id: 5,
    img: KTBLogo,
    name: "History 6",
    msg: "Description6...",
    time: "10:35",
    bot_lastweek: true,
  },
  {
    id: 6,
    img: KTBLogo,
    name: "History 7",
    msg: "Description7...",
    time: "10:35",
    bot_lastmonth: true,
  },
  {
    id: 7,
    img: KTBLogo,
    name: "History 8",
    msg: "Description8...",
    time: "10:35",
    bot_today: true,
  },
  {
    id: 8,
    img: KTBLogo,
    name: "History 9",
    msg: "Description9...",
    time: "10:35",
    bot_today: true,
  },
  {
    id: 9,
    img: KTBLogo,
    name: "History 10",
    msg: "Description10...",
    time: "10:35",
    bot_today: true,
  },
  {
    id: 10,
    img: KTBLogo,
    name: "History 11",
    msg: "Description11...",
    time: "10:35",
    bot_yesterday: true,
  },
  {
    id: 11,
    img: KTBLogo,
    name: "History 12",
    msg: "Description12...",
    time: "10:35",
    bot_yesterday: true,
  }
];

const Chat_History = [
  {
    type: "msg",
    message: "Hi 👋🏻, How are you ?",
    incoming: true,
    outgoing: false,
  },
  {
    type: "divider",
    text: "Today",
  },
  {
    type: "msg",
    message: "Can you send me an abstarct image?",
    incoming: false,
    outgoing: true,
  },
  {
    type: "msg",
    subtype: "img",
    message: "Here You Go",
    img: faker.image.abstract(),
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    message: "Can you please send this in file format?",
    incoming: false,
    outgoing: true,
  },

  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "reply",
    reply: "Nice !",
    message: "Here You Go",
    incoming: false,
    outgoing: true,
  },
];

const Message_options = [
  {
    title: "Reply",
  },
  {
    title: "React to message",
  },
  {
    title: "Forward message",
  },
  {
    title: "Star message",
  },
  {
    title: "Report",
  },
  {
    title: "Delete Message",
  },
];

export {
  Profile_Menu,
  Nav_Setting,
  Nav_Buttons,
  ChatList,
  Chat_History,
  Message_options,
};
